import React, { useContext, useState } from 'react'
import { emitRegisterName } from '../clientCommunication'
import GameContext from '../context/GameContext'
import './MainMenu.css'

export function RegisterName () {
  const ctx = useContext(GameContext)
  const [playerName, setPlayerName] = useState('')

  function registerName (name) {
    if (playerName !== '' && name.length > 0) {
      emitRegisterName(name)
      ctx.setStage('mainMenu')
    }
  }

  const enterClick = (cEvent) => {
    if (cEvent.keyCode === 13) {
      registerName(playerName)
    }
  }

  return <>
    <div className="mainMenu">
    <input className="optionsField" onChange={(e) => setPlayerName(e.target.value)} onKeyDown={(e) => enterClick(e)} />
      <button onClick={() => registerName(playerName)} className="menuButton">Set Name </button>
    </div>
  </>
}

import { colors, createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class TiltedSquares extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'TiltedSquares', 4)
    this.assemble()
  }

  assemble () {
    // Ice
    this._addRectangularIce(375, 400, 350, 800)

    // Wasser links
    this._addRectangularWater(50, 400, 100, 800)
    // Wasser rechts
    this._addRectangularWater(950, 400, 100, 800)
    // Wasser oben links
    this._addRectangularWater(236.5, 160, 473, 323)
    // Wasser unten links
    this._addRectangularWater(236.5, 640, 473, 323)
    // Wasser oben rechts
    this._addRectangularWater(500, 60, 1000, 120)
    // Wasser unten rechts
    this._addRectangularWater(500, 740, 1000, 120)

    // Mittleres kleines Quadrat
    this._addRectangularWall(550, 400, 110, 110, true, true, colors.wall, radians[45])
    // Oberes kleines Quadrat
    this._addRectangularWall(473, 120, 110, 110, true, true, colors.wall, radians[45])
    // Unteres kleines Quadrat
    this._addRectangularWall(473, 680, 110, 110, true, true, colors.wall, radians[45])

    // Oben rechts großes Quadrat
    this._addRectangularWall(920, 100, 250, 250, true, true, colors.wall, radians[45])
    // Unten rechts großes Quadrat
    this._addRectangularWall(920, 700, 250, 250, true, true, colors.wall, radians[45])

    // Loch
    this._hole = createHole(810, 400)

    this._ballSpawns = [{ x: 150, y: 400 }]
  }
}

import React, { useContext } from 'react'
import GameContext from '../context/GameContext'
import { LobbyCode } from './LobbyCode'
import { PlayerInfo } from './PlayerInfo'

export function WaitingLobby () {
  const ctx = useContext(GameContext)
  const players = ctx.players
  const lobbyId = players[0] ? players[0].socketId : ''
  return <>
    <PlayerInfo></PlayerInfo>
    <LobbyCode lobbyId={lobbyId}></LobbyCode>
  </>
}

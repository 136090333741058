import { createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class PocketTest extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'PocketTest', 10)
    this.assemble()
  }

  assemble () {
    this._addRectangularWall(500, 400, 1000, 20)
    this._addRectangularWall(460, 600, 20, 260)

    // Loch
    this._hole = createHole(550, 520)

    this._ballSpawns = [{ x: 350, y: 520 }]

    // Borders am Ende erstellen damit die oben sind
    this._createBorders()
  }
}

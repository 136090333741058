import { colors, createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class Cross extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'Cross', 4)
    this.assemble()
  }

  assemble () {
    // Wasser
    this._addRectangularWater(200, 150, 400, 300)
    this._addRectangularWater(200, 650, 400, 300)
    this._addRectangularWater(800, 150, 400, 300)
    this._addRectangularWater(800, 650, 400, 300)
    this._addRectangularWater(50, 400, 100, 200)
    this._addRectangularWater(950, 400, 100, 200)

    // Walls
    this._addRectangularWall(120, 400, 40, 200, true, true, colors.wall)
    this._addRectangularWall(880, 400, 40, 200, true, true, colors.wall)
    this._addRectangularWall(500, 20, 200, 40, true, true, colors.wall)
    this._addRectangularWall(500, 780, 200, 40, true, true, colors.wall)

    // Create round Obsticles
    this._addCircularWall(500, 300, 40, true, true, colors.wall)
    this._addCircularWall(600, 400, 40, true, true, colors.wall)
    this._addCircularWall(500, 500, 40, true, true, colors.wall)
    this._addCircularWall(400, 400, 40, true, true, colors.wall)

    // Loch
    this._hole = createHole(500, 400)

    this._ballSpawns = [{ x: 500, y: 100 }, { x: 800, y: 400 }, { x: 500, y: 700 }, { x: 200, y: 400 }]
  }
}

import { colors, createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class HMap extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'HMap', 6)
    this.assemble()
  }

  assemble () {
    // leftRectangleLeftUpperCorner
    this._addRectangularWall(60, 60, 200, 200, true, true, colors.yellow, radians[45])
    // leftRectangleRightUpperCorner
    this._addRectangularWall(350, 60, 200, 200, true, true, colors.yellow, radians[45])

    // rightRectangleLeftUpperCorner
    this._addRectangularWall(650, 60, 200, 200, true, true, colors.yellow, radians[45])
    // rightRectangleLeftLowerCorner
    this._addRectangularWall(650, 740, 200, 200, true, true, colors.yellow, radians[45])
    // rightRectangleRightLowerCorner
    this._addRectangularWall(940, 740, 200, 200, true, true, colors.yellow, radians[45])

    // upperWallBG
    this._addRectangularWall(500, 35, 1000, 70, true, true, colors.background)
    // // lowerWallBG
    this._addRectangularWall(500, 765, 1000, 70, true, true, colors.background)

    // // leftWallBG
    this._addRectangularWall(35, 400, 70, 800, true, true, colors.background)
    // // rightWallBG
    this._addRectangularWall(965, 400, 70, 800, true, true, colors.background)

    // // middleUpperPartBG
    this._addRectangularWall(500, 150, 280, 280, true, true, colors.background)
    // // middleLowerPartBG
    this._addRectangularWall(500, 650, 280, 280, true, true, colors.background)

    // upperLeftWallBorder
    this._addRectangularWall(350, 185, 40, 250, true, true, colors.wood)
    // upperRightWallBorder
    this._addRectangularWall(650, 185, 40, 250, true, true, colors.wood)
    // lowerLeftWallBorder
    this._addRectangularWall(350, 625, 40, 250, true, true, colors.wood)
    // lowerRightWallBorder
    this._addRectangularWall(650, 625, 40, 250, true, true, colors.wood)

    // leftWallBorder
    this._addRectangularWall(60, 400, 40, 700, true, true, colors.wood)
    // rightWallBorder
    this._addRectangularWall(940, 400, 40, 700, true, true, colors.wood)

    // upperLeftWallBorder
    this._addRectangularWall(205, 60, 330, 40, true, true, colors.wood)
    // upperRightWallBorder
    this._addRectangularWall(795, 60, 330, 40, true, true, colors.wood)

    // lowerLeftWallBorder
    this._addRectangularWall(205, 740, 330, 40, true, true, colors.wood)
    // lowerRightWallBorder
    this._addRectangularWall(795, 740, 330, 40, true, true, colors.wood)

    // middelUpperPart
    this._addRectangularWall(500, 290, 300, 40, true, true, colors.wood)
    // middelLowerPart
    this._addRectangularWall(500, 520, 300, 40, true, true, colors.wood)

    // leftTriangle
    this._addTriangleWall(200, 420, 90, true, true, colors.red, radians[90])
    // rightTriangle
    this._addTriangleWall(800, 380, 90, true, true, colors.red, radians[270])

    // Loch
    this._hole = createHole(880, 150)

    // Ball
    this._ballSpawns = [{ x: 200, y: 680 }]
  }
}

export const settings = {
  posUpdateDelay: 24,
  // Client Side Prediction
  clientSidePredictionActive: true,
  maxSpeed: 40,
  teachClientSidePrediction: false,
  variableFactorActive: true,
  nonVariableFactor: 0.86,
  mapDebuggingActive: false,
  maxClientsPerLobby: 9,
  maxTime: 60,
  maxForce: 0.1,
  // Wenn aktiviert, wird der Ball bei Kontakt mit Wasser nicht auf die ursprüngliche Position
  // zurückgesetzt, sondern auf die Startposition des vorherigen Zuges. Wenn es noch keine gibt,
  // zum Spawn
  waterHardmode: false,
  // Matter js settings
  engineOptions: {
    timing: {
      isFixed: true,
      timeScale: 1,
      timestamp: 0,
      lastElapsed: 0,
      lastDelta: 0
    },
    gravity: {
      x: 0,
      y: 0,
      scale: 0
    }
  },
  runnerOptions: {
    isFixed: true
  },
  developmentOptions: {
    // Fügt man einen Namen einer Map als String ein, wird diese Map direkt gestartet mit einem Spieler
    // und 10 Zügen. Zum Entwickeln von Maps
    fastLoadMap: undefined
  }
}

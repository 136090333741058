import { colors, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class Diagonals extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'Diagonals', 5)
    this.assemble()
  }

  assemble () {
    // Sand
    this._addRectangularSand(750, 200, 500, 400)

    // Ice
    this._addRectangularIce(250, 600, 500, 400)

    // Wasser
    this._addRectangularWater(40, 400, 80, 800)
    this._addRectangularWater(960, 400, 80, 800)

    // Walls
    this._addRectangularWall(500, 0, 1000, 40, true, true, colors.metal)
    this._addRectangularWall(500, 800, 1000, 40, true, true, colors.metal)

    this._addRectangularWall(80, 400, 40, 700, true, true, colors.metal, radians[45])
    this._addRectangularWall(97, 800, 40, 400, true, true, colors.metal, radians[45])

    this._addRectangularWall(700, 200, 40, 1000, true, true, colors.metal, radians[45])

    this._addRectangularWall(500, 0, 100, 100, true, true, colors.metal, radians[45])

    this._addRectangularWall(710, 780, 200, 200, true, true, colors.metal, radians[45])
    this._addRectangularWall(710, 400, 200, 200, true, true, colors.metal, radians[45])

    // Loch
    this._addHole(825, 590)

    this._ballSpawns = [{ x: 160, y: 110 }]
  }
}

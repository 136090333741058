import Matter from 'matter-js'
import { MapObject, mapObjectTypes } from '../entities/MapObject.js'
import { settings } from '../settings.js'
import { colors, createCarpet, createHole, createIce, createRoundWall, createSand, createWall, createWater, createRoundWater, createRoundSand, createRoundIce, createTriangle } from '../utils/mapUtils.js'

const Bodies = Matter.Bodies

export const ballAirFriction = 0.025

/**
 * Abstrakte Klasse GameMap, Maps sollen von dieser Klasse erben, damit alle Maps gemeinsame Attribute
 * und Methoden haben, welche von anderen Klassen erwartet werden.
 *
 * @class GameMap
 */
export class GameMap {
  constructor (isClientMap, name, maxTurns) {
    // sicherstellen das GameMap nicht selber erzeugt wird.
    if (this.constructor.name === 'GameMap') {
      throw new Error('GameMap is an abstract class and cannot be instantiated')
    }
    this._isClientMap = isClientMap
    this._mapObjects = []
    this._name = name
    this._wallVisibility = settings.mapDebuggingActive
    this._ballSpawns = [{ x: 275, y: 300 }]
    this._ballsSpawned = 0
    this._hole = undefined
    this._maxTurns = maxTurns
  }

  getMaxTurns () {
    return this._maxTurns
  }

  _createBorders (bouncy = false, color = colors.border) {
    // Linke Border
    this._addRectangularWall(0, 400, 50, 800, true, true, color, undefined, bouncy)
    // Rechte Border
    this._addRectangularWall(1000, 400, 50, 800, true, true, color, undefined, bouncy)
    // Obere Border
    this._addRectangularWall(500, 0, 1000, 50, true, true, color, undefined, bouncy)
    // Untere Border
    this._addRectangularWall(500, 800, 1000, 50, true, true, color, undefined, bouncy)
  }

  getBallSpawnPosition () {
    return { x: this._ballSpawns[this._ballsSpawned - 1].x, y: this._ballSpawns[this._ballsSpawned - 1].y }
  }

  /**
   * Liefert den Namen des Map. Immer gleich wie der Klassenname
   * @returns Name er Map
   */
  getName () {
    return this._name
  }

  getObjects () {
    return this._mapObjects
  }

  /**
   * Erzeugt einen Ball
   * @param {*} counter Nummer die hochgezählt wird, gleichzeitig Ball-Label
   * @param {*} isStatic
   * @param {*} color
   * @returns Body Objekt des Balls
   */
  createBall (counter, isStatic, color) {
    const collisionFilter = { category: 0, mask: 2, group: 2 }
    if (this._ballsSpawned === this._ballSpawns.length) {
      this._ballsSpawned = 0
    }
    this._ballsSpawned = this._ballsSpawned + 1
    return Bodies.circle(
      this._ballSpawns[this._ballsSpawned - 1].x,
      this._ballSpawns[this._ballsSpawned - 1].y,
      13,
      {
        label: counter.toString(),
        isStatic: isStatic,
        render: {
          fillStyle: color,
          lineWidth: 1
        },
        frictionAir: ballAirFriction,
        restitution: 0.9,
        collisionFilter: collisionFilter
      })
  }

  /**
   * Liefert das Loch der Map
   * @returns Body Objekt des Lochs
   */
  getHole () {
    return this._hole
  }

  _addRectangularWall (x, y, width, height, visibleWall = true, collideWithBall = true, color = colors.wall, angle = 0, bouncy = false) {
    const body = createWall(x, y, width, height, visibleWall, collideWithBall, color, angle, bouncy)
    const type = bouncy ? mapObjectTypes.bouncyRectangularWall : mapObjectTypes.rectangularWall

    this._mapObjects.push(new MapObject(this._mapObjects.length, body, type, color))
  }

  _addCircularWall (x, y, radius, visibleWall = true, collideWithBall = true, color = colors.wall, bouncy = false) {
    const body = createRoundWall(x, y, radius, collideWithBall, visibleWall, color, bouncy)
    const type = bouncy ? mapObjectTypes.bouncyCircularWall : mapObjectTypes.circularWall

    this._mapObjects.push(new MapObject(this._mapObjects.length, body, type, color))
  }

  _addRectangularWater (x, y, width, height, angle = 0) {
    const body = createWater(x, y, width, height, angle)
    this._mapObjects.push(new MapObject(this._mapObjects.length, body, mapObjectTypes.water, colors.water))
  }

  _addCircularWater (x, y, radius) {
    const body = createRoundWater(x, y, radius)
    this._mapObjects.push(new MapObject(this._mapObjects.length, body, mapObjectTypes.water, colors.water))
  }

  _addRectangularSand (x, y, width, height) {
    const body = createSand(x, y, width, height)
    this._mapObjects.push(new MapObject(this._mapObjects.length, body, mapObjectTypes.sand, colors.sand))
  }

  _addCircularSand (x, y, radius) {
    const body = createRoundSand(x, y, radius)
    this._mapObjects.push(new MapObject(this._mapObjects.length, body, mapObjectTypes.sand, colors.water))
  }

  _addRectangularIce (x, y, width, height) {
    const body = createIce(x, y, width, height)
    this._mapObjects.push(new MapObject(this._mapObjects.length, body, mapObjectTypes.ice, colors.ice))
  }

  _addCircularIce (x, y, radius) {
    const body = createRoundIce(x, y, radius)
    this._mapObjects.push(new MapObject(this._mapObjects.length, body, mapObjectTypes.ice, colors.water))
  }

  _addCarpet (x, y, width, height) {
    const body = createCarpet(x, y, width, height)
    this._mapObjects.push(new MapObject(this._mapObjects.length, body, mapObjectTypes.carpet, colors.carpet))
  }

  _addHole (x, y) {
    const body = createHole(x, y)
    this._hole = body
    this._mapObjects.push(new MapObject(this._mapObjects.length, body, mapObjectTypes.hole, colors.hole))
  }

  // Für Collision Detection besser Radius >= 80 (ANGERATEN, KANNST NACH TEST AUCH EINEN KLEINEREN NEHMEN BARG!!!)
  _addTriangleWall (x, y, radius, visibleWall = true, collideWithBall = true, color = colors.wall, angle = 0, bouncy = false) {
    const body = createTriangle(x, y, radius, visibleWall, collideWithBall, color, angle, bouncy)
    const type = bouncy ? mapObjectTypes.bouncyRectangularWall : mapObjectTypes.triangleWall

    this._mapObjects.push(new MapObject(this._mapObjects.length, body, type, color))
  }
}

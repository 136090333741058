import { colors, createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class Only90sKidsWillRemember extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'Only90sKidsWillRemember', 3)
    this.assemble()
  }

  assemble () {
    // Linke Border
    this._addRectangularWall(0, 400, 50, 800, true, true, colors.border, undefined, true)
    // Rechte Border
    this._addRectangularWall(1000, 300, 50, 800, true, true, colors.border, undefined, true)
    // Obere Border
    this._addRectangularWall(500, 0, 1000, 50, true, true, colors.border, undefined, true)
    // Untere Border
    this._addRectangularWall(400, 800, 1000, 50, true, true, colors.border, undefined, true)

    this._addRectangularWall(1000, 750, 50, 100)
    this._addRectangularWall(950, 800, 100, 50)

    this._addRectangularSand(950, 750, 100, 100)

    // Loch
    this._hole = createHole(940, 740)

    this._ballSpawns = [{ x: 80, y: 80 }]
  }
}

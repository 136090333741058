export class Client {
  /**
   * Erstellt ein Client Objekt für die ClientEngine.
   * @param {Number} id
   * @param {Matter.Body} body
   * @param {PIXI.Graphics} graphic
   */
  constructor (id, body, graphic) {
    this._id = id
    this._body = body
    this._graphic = graphic
  }

  getId () {
    return this._id
  }

  getBody () {
    return this._body
  }

  getGraphic () {
    return this._graphic
  }

  updateGraphicPosition () {
    this.getGraphic().x = this.getBody().position.x
    this.getGraphic().y = this.getBody().position.y
  }
}

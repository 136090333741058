import Matter from 'matter-js'

// Generate a force vector with a direction defined by the vector between pos1 and pos2 and a magnitude defined by the distance between pos1 and pos2
export function generateForceVector (pos1, pos2, maxForce, maxMagnitude) {
  // Calc Vector between the two positions
  const deltaVector = Matter.Vector.sub(pos1, pos2)
  // Calc distance between the two positions
  let deltaVectorMagnitude = Matter.Vector.magnitude(deltaVector)
  // Calc force factor
  let force = 0
  if (deltaVectorMagnitude >= maxMagnitude) {
    deltaVectorMagnitude = maxMagnitude
  }
  force = deltaVectorMagnitude / maxMagnitude * maxForce

  // Normalize Vector to length = 1 and multiply it with the force factor
  const normalizedDelta = Matter.Vector.normalise(deltaVector)
  const forceVector = Matter.Vector.mult(normalizedDelta, force)
  return forceVector
}

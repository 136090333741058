import { colors, createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class Fibo extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'Fibo', 4)
    this.assemble()
  }

  assemble () {
    this._addRectangularWater(500, 43.75, 1000, 87.5)
    this._addRectangularWater(500, 756.25, 1000, 87.5)

    this._addRectangularWall(625, 525, 40, 375, true, true, colors.wall)
    this._addRectangularWall(625, 150, 40, 125, true, true, colors.wall, 0, true)
    this._addRectangularWall(750, 462.5, 250, 40, true, true, colors.wall)
    this._addRectangularWall(750, 515, 40, 143.5, true, true, colors.wall)

    this._addRectangularIce(812.5, 275, 375, 375)

    // Loch
    this._hole = createHole(687.5, 525)

    this._ballSpawns = [{ x: 66, y: 666 }]

    // Borders am Ende erstellen damit die oben sind
    this._createBorders()
  }
}

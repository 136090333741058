import { handleHit } from './clientCommunication.js'
import { getMousePos } from './renderer.js'
// import { showCreateJoinButtons, showLobbyInfo, showLobbySettings, showNextMapButtons } from './gameInfo.js'

/**
 * Modul handelt die Interaktion mit dem Browser.
 * Hier werden Events abonniert.
 */

export const initBrowserEventListeners = () => {
  const body = document.querySelector('body')

  if (body) {
    body.addEventListener('click', () => {
      const mousePos = getMousePos()
      if (mousePos.x < 1000 && mousePos.y < 800) {
        handleHit({ x: mousePos.x, y: mousePos.y })
      }
    })
  }
}

export const mapObjectTypes = {
  rectangularWall: 'rectangularWall',
  circularWall: 'circleWall',
  bouncyRectangularWall: 'bouncyRectangularWall',
  bouncyCircularWall: 'bouncyCircularWall',
  sand: 'sand',
  ice: 'ice',
  water: 'water',
  carpet: 'carpet',
  hole: 'hole',
  triangleWall: 'triangleWall'
}

/**
 * Klasse welche einzelne Bestandteile (Matter Bodies, z.B. eine Wand oder Eisfläche) von Maps,
 * sowie Eigenschaften wie Typ und Farbe enthält.
 * @class MapObject
 */
export class MapObject {
  /**
   * Erstellt ein Map-Objekt.
   * @param {Number} id
   * @param {Matter.Body} body
   * @param {*} type
   */
  constructor (id, body, type, color) {
    this._id = id
    this._body = body
    this._type = type
    this._color = color
  }

  getId () {
    return this._id
  }

  getBody () {
    return this._body
  }

  getType () {
    return this._type
  }

  getColor () {
    return this._color
  }
}

import React, { useContext } from 'react'
import { getClientMap } from '../clientEngine'
import GameContext from '../context/GameContext'

export function PlayerInfo () {
  const ctx = useContext(GameContext)
  let allPlayers = ctx.players

  if (ctx.stage === 'afterRound') {
    allPlayers = allPlayers.sort((a, b) => {
      if (a.score > b.score) {
        return 1
      } else {
        return -1
      }
    })
  }

  function getPositionCharacter (index) {
    if (index === 0) {
      return '👑'
    } else if (index === 1) {
      return '🥈'
    } else if (index === 2) {
      return '🥉'
    } else if (index === 3) {
      return '4️⃣'
    } else if (index === 4) {
      return '5️⃣'
    } else if (index === 5) {
      return '6️⃣'
    } else if (index === 6) {
      return '7️⃣'
    } else if (index === 7) {
      return '8️⃣'
    } else if (index === 8) {
      return '9️⃣'
    }
  }

  return <div className="playerInfo">
    {allPlayers.map((player, index) =>
      <div key={index} style={{ color: player.pocketed ? 'lightgrey' : 'white', fontFamily: 'sans-serif', opacity: 0.8 }}>
        {ctx.stage === 'afterRound' && <div style={{ display: 'inline' }}>{getPositionCharacter(index)}<span>&nbsp;</span></div>}
        {(ctx.stage === 'gameRunning' || ctx.stage === 'afterRound') && <div style={{ display: 'inline', color: player.color !== '' ? player.color : 'grey', fontSize: '24px', fontWeight: 'bolder' }}>{player.activeTurn || ctx.stage === 'afterRound' ? '●' : '○'}</div>}
        <div style={{ display: 'inline', height: '26px' }}> {ctx.stage === 'afterRound' && player.score + ' '} {player.name}</div>
        {(ctx.stage === 'gameRunning' || ctx.stage === 'afterRound') && <div style={{ display: 'inline' }}> {'(' + player.numberOfTurns + (player.activeTurn ? '/' + getClientMap().getMaxTurns() : '') + ')'}</div>}
        {(ctx.stage === 'gameRunning' && player.activeTurn) && <div style={{ display: 'inline' }}> {ctx.time}</div>}
      </div>
    )
    }
  </div>
}

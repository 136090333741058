import { colors, createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class DockInwards extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'DockInwards', 4)
    this.assemble()
  }

  assemble () {
    // Klotz
    this._addRectangularWall(500, 480, 440, 200, true, true, colors.wall)

    // Kante Links
    this._addRectangularWall(200, 300, 100, 100, true, true, colors.wall, radians[45])

    // Wasser oben
    this._addRectangularWater(500, 150, 1000, 300)
    // Wasser links
    this._addRectangularWater(100, 440, 200, 280)
    // Wasser rechts
    this._addRectangularWater(900, 440, 200, 280)
    // kleines Wasser unten rechts
    this._addRectangularWater(760, 560, 80, 40)

    // Loch
    this._hole = createHole(760, 500)

    this._ballSpawns = [{ x: 240, y: 550 }]

    // Borders am Ende erstellen damit die oben sind
    this._createBorders()
  }
}

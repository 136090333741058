import { colors, createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class IceBath extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'IceBath', 5)
    this.assemble()
  }

  assemble () {
    // Ice
    this._addRectangularIce(400, 400, 830, 800)
    this._addRectangularIce(500, 75, 1000, 205)
    this._addRectangularIce(960, 400, 30, 800)
    this._addRectangularIce(500, 545, 1000, 485)

    // Wasser von links nach rechts
    this._addRectangularWater(80, 30, 220, 60)
    this._addRectangularWater(290, 85, 230, 150, radians[45])
    this._addRectangularWater(520, 110, 402, 220)
    this._addRectangularWater(550, 300, 400, 100, radians[45])
    this._addRectangularWater(602, 196, 250, 420)
    this._addRectangularWater(725, 100, 100, 100, radians[45])
    this._addRectangularWater(850, 60, 270, 120)

    // Berge von rechts nach links
    this._addRectangularWall(850, 800, 300, 540, true, true, colors.wall, radians[45])
    this._addRectangularWall(770, 680, 120, 120, true, true, colors.wall)
    this._addRectangularWall(767, 620, 80, 80, true, true, colors.wall, radians[45])
    this._addRectangularWall(360, 750, 200, 100, true, true, colors.wall, radians[45])
    this._addRectangularWall(150, 750, 350, 100, true, true, colors.wall)
    this._addRectangularWall(10, 710, 200, 200, true, true, colors.wall, radians[45])
    // Oben links der Teil
    this._addRectangularWall(135, 300, 126, 100, true, true, colors.wall, radians[45])
    this._addRectangularWall(75, 270, 100, 100, true, true, colors.wall)
    this._addRectangularWall(75, 330, 137, 100, true, true, colors.wall)
    this._addRectangularWall(15, 350, 126, 100, true, true, colors.wall, radians[45])

    // Loch
    this._hole = createHole(90, 130)

    this._ballSpawns = [{ x: 880, y: 240 }]

    // Borders am Ende erstellen damit die oben sind
    this._createBorders()
  }
}

import { createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class Believe extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'Believe', 5)
    this.assemble()
  }

  assemble () {
    this._addRectangularWater(150, 600, 450, 450, radians[45])
    this._addRectangularWater(850, 200, 450, 450, radians[45])

    // Loch
    this._hole = createHole(920, 520)

    this._ballSpawns = [{ x: 80, y: 280 }]

    // Borders am Ende erstellen damit die oben sind
    this._createBorders(true)
  }
}

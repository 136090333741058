import { BevelFilter } from '@pixi/filter-bevel'
import { DropShadowFilter } from '@pixi/filter-drop-shadow'
import { NoiseFilter } from '@pixi/filter-noise'
import Matter from 'matter-js'
import * as PIXI from 'pixi.js'
import { mapObjectTypes } from '../entities/MapObject'
// @ts-ignore
import grassImage from './assets/grass.png'
import { getClientMap, getClients, updateClientEngine } from './clientEngine'

let app
let ballContainer
let mousePointerGraphic
let mousePointerContainer
let currentPlayerIndex
let interactionManager
let mousePos

export const initRenderer = (isSplashScreen = false) => {
  app = new PIXI.Application({
    antialias: false,
    // @ts-ignore
    view: document.getElementById('gameCanvas'),
    width: 1000,
    height: 800,
    resolution: 1
  })
  app.ticker.maxFPS = 144

  drawMap()

  interactionManager = new PIXI.InteractionManager(app.renderer)
  interactionManager.cursorStyles.default = 'crosshair'

  if (isSplashScreen) {
    drawSplashScreenText()
  } else {
    initMousePointer()
  }

  app.ticker.add(() => {
    if (isSplashScreen) {
      updateClientEngine(20)
    } else {
      updateClientEngine(app.ticker.deltaMS)
    }
  })
}

export const getMousePos = () => {
  return mousePos
}

const initMousePointer = () => {
  mousePointerGraphic = new PIXI.Graphics()
  mousePointerContainer = new PIXI.Container()
  mousePointerContainer.addChild(mousePointerGraphic)
  mousePointerContainer.interactive = true
  mousePointerContainer.on('pointermove', (e) => {
    mousePos = e.data.global
    updateMousePointerGraphic(e.data.global)
  })
  mousePointerContainer.alpha = 0
  app.stage.addChild(mousePointerContainer)
}

const updateMousePointerGraphic = (mousePos) => {
  mousePointerGraphic.clear()
  const currentClient = getClients().find(client => client && client.getId() === currentPlayerIndex)
  if (currentClient && (mousePos.x < 1000 && mousePos.y < 800)) {
    const ballPos = { x: currentClient.getBody().position.x, y: currentClient.getBody().position.y }
    const adjustedBallPos = getAdjustedBallPos(ballPos, mousePos)
    mousePointerGraphic.lineStyle(2, 0, 0.5)
    mousePointerGraphic.moveTo(ballPos.x - adjustedBallPos.x, ballPos.y - adjustedBallPos.y)
    mousePointerGraphic.lineTo(mousePos.x, mousePos.y)
    mousePointerGraphic.endFill()
  }
}

export const getAdjustedBallPos = (ballPos, mousePos) => {
  const radius = 14
  const deltaVector = Matter.Vector.sub(ballPos, mousePos)
  const ex = { x: 1, y: 0 }
  let alpha = Math.acos(Matter.Vector.dot(deltaVector, ex) / Matter.Vector.magnitude(deltaVector))
  if (deltaVector.y < 0) {
    alpha = 2 * Math.PI - alpha
  }
  return { x: Math.cos(alpha) * radius, y: Math.sin(alpha) * radius }
}

export const showMousePointer = (currentPlayerIndexValue) => {
  mousePointerContainer.alpha = 1
  currentPlayerIndex = currentPlayerIndexValue
  if (interactionManager) {
    updateMousePointerGraphic(interactionManager.mouse.global)
  }
}

export const hideMousePointer = () => {
  if (mousePointerContainer) {
    mousePointerContainer.alpha = 0
  }
}

const drawSplashScreenText = () => {
  const textContainer = new PIXI.Container()
  const text = new PIXI.Text('migolf  io', { font: 'Arial', fill: '#FFFFFF', fontSize: 90, fontWeight: '900' })
  text.x = 275
  text.y = 150
  textContainer.addChild(text)
  // @ts-ignore
  textContainer.filters = [new DropShadowFilter({ blur: 0, shadowOnly: false, distance: 1, rotation: 33 }), new BevelFilter({ thickness: 4, lightAlpha: 0.1, shadowAlpha: 0.5, rotation: 66 })]
  app.stage.addChild(textContainer)
}

export const cleanUpRenderer = () => {
  if (app) {
    hideMousePointer()
    app.stage.children.forEach(child => app.stage.removeChild(child))
    app.ticker.destroy()
    app.renderer.destroy()
    app = undefined
    ballContainer = undefined
    mousePointerGraphic = undefined
    mousePointerContainer = undefined
    currentPlayerIndex = undefined
    interactionManager = undefined
  }
}

export const removeBallGraphic = (graphic) => {
  ballContainer.removeChild(graphic)
}

export const drawNewBall = (color) => {
  const graphic = new PIXI.Graphics()
  graphic.beginFill(Number('0x' + color.substring(1)))
  graphic.drawCircle(0, 0, 13)
  graphic.endFill()

  ballContainer.addChild(graphic)
  // @ts-ignore
  ballContainer.filters = [new DropShadowFilter({ blur: 1, shadowOnly: false, distance: 3, rotation: 33 }), new BevelFilter({ thickness: 4, lightAlpha: 0.1, shadowAlpha: 0.1, rotation: 66 })]
  return graphic
}

export const drawMap = () => {
  const map = getClientMap()
  drawBackground()
  drawIce(map.getObjects().filter(object => object.getType() === mapObjectTypes.ice))
  drawSand(map.getObjects().filter(object => object.getType() === mapObjectTypes.sand))
  drawWater(map.getObjects().filter(object => object.getType() === mapObjectTypes.water))
  drawCarpet(map.getObjects().filter(object => object.getType() === mapObjectTypes.carpet))
  drawHole(map.getHole())
  // BallContainer wird hier erst initialisiert damit Wände über Bälle gerendert werden.
  // Dadurch wirft der Ball keinen Schatten auf Wände.
  ballContainer = new PIXI.Container()
  app.stage.addChild(ballContainer)
  drawWalls(
    map.getObjects().filter(object => (object.getType() === mapObjectTypes.rectangularWall || object.getType() === mapObjectTypes.triangleWall) && object.getBody().render.visible),
    map.getObjects().filter(object => object.getType() === mapObjectTypes.circularWall && object.getBody().render.visible),
    map.getObjects().filter(object => object.getType() === mapObjectTypes.bouncyRectangularWall),
    map.getObjects().filter(object => object.getType() === mapObjectTypes.bouncyCircularWall))
}

const drawBackground = () => {
  const grassContainer = new PIXI.Container()
  const grassTexture = PIXI.Texture.from(grassImage)
  const backgroundSprite = new PIXI.TilingSprite(grassTexture, 1000, 800)
  backgroundSprite.width = 1000
  backgroundSprite.height = 800
  grassContainer.addChild(backgroundSprite)
  // @ts-ignore
  grassContainer.filters = [new NoiseFilter(0.02)]
  app.stage.addChild(grassContainer)
}

const drawIce = (iceObjects) => {
  const iceContainer = new PIXI.Container()
  iceObjects.forEach(object => {
    const body = object.getBody()
    const bodyGraphics = new PIXI.Graphics()
    bodyGraphics.beginFill(0xB5EAFF)
    let firstVertex
    body.vertices.forEach((vertex, index) => {
      if (index === 0) {
        firstVertex = vertex
        bodyGraphics.moveTo(vertex.x, vertex.y)
      } else {
        bodyGraphics.lineTo(vertex.x, vertex.y)
      }
    })
    if (firstVertex) {
      // @ts-ignore
      bodyGraphics.lineTo(firstVertex.x, firstVertex.y)
    }
    bodyGraphics.endFill()

    iceContainer.addChild(bodyGraphics)
  })

  app.stage.addChild(iceContainer)
}

const drawCarpet = (carpetObjects) => {
  const carpetContainer = new PIXI.Container()
  carpetObjects.forEach(object => {
    const body = object.getBody()
    const bodyGraphics = new PIXI.Graphics()
    bodyGraphics.beginFill(0xC21616)
    let firstVertex
    body.vertices.forEach((vertex, index) => {
      if (index === 0) {
        firstVertex = vertex
        bodyGraphics.moveTo(vertex.x, vertex.y)
      } else {
        bodyGraphics.lineTo(vertex.x, vertex.y)
      }
    })
    if (firstVertex) {
      // @ts-ignore
      bodyGraphics.lineTo(firstVertex.x, firstVertex.y)
    }
    bodyGraphics.endFill()

    // @ts-ignore
    carpetContainer.filters = [new NoiseFilter(0.05)]

    carpetContainer.addChild(bodyGraphics)
  })

  app.stage.addChild(carpetContainer)
}

const drawSand = (sandObjects) => {
  const sandContainer = new PIXI.Container()
  sandObjects.forEach(object => {
    const body = object.getBody()
    const bodyGraphics = new PIXI.Graphics()
    bodyGraphics.beginFill(0xFFEBA3)
    let firstVertex
    body.vertices.forEach((vertex, index) => {
      if (index === 0) {
        firstVertex = vertex
        bodyGraphics.moveTo(vertex.x, vertex.y)
      } else {
        bodyGraphics.lineTo(vertex.x, vertex.y)
      }
    })
    if (firstVertex) {
      // @ts-ignore
      bodyGraphics.lineTo(firstVertex.x, firstVertex.y)
    }
    bodyGraphics.endFill()

    // @ts-ignore
    sandContainer.filters = [new NoiseFilter(0.05)]

    sandContainer.addChild(bodyGraphics)
  })

  app.stage.addChild(sandContainer)
}

const drawWater = (waterObjects) => {
  const waterContainer = new PIXI.Container()
  waterObjects.forEach(object => {
    const body = object.getBody()
    const bodyGraphics = new PIXI.Graphics()
    bodyGraphics.beginFill(0x4F8CFF)
    let firstVertex
    body.vertices.forEach((vertex, index) => {
      if (index === 0) {
        firstVertex = vertex
        bodyGraphics.moveTo(vertex.x, vertex.y)
      } else {
        bodyGraphics.lineTo(vertex.x, vertex.y)
      }
    })
    if (firstVertex) {
      // @ts-ignore
      bodyGraphics.lineTo(firstVertex.x, firstVertex.y)
    }
    bodyGraphics.endFill()

    // @ts-ignore
    waterContainer.filters = [new BevelFilter({ thickness: 3, lightAlpha: 0, shadowAlpha: 0.4, rotation: 246 })]

    waterContainer.addChild(bodyGraphics)
  })

  app.stage.addChild(waterContainer)
}

const drawHole = (holeBody) => {
  const holeContainer = new PIXI.Container()

  const holeCircle = new PIXI.Graphics()
  holeCircle.beginFill(0)
  holeCircle.drawCircle(holeBody.position.x, holeBody.position.y, 19)
  holeCircle.endFill()

  holeContainer.addChild(holeCircle)
  // @ts-ignore
  holeContainer.filters = [new BevelFilter({ rotation: 66, lightAlpha: 0.3, lightColor: 0xFFE9DD, thickness: 4 })]
  app.stage.addChild(holeContainer)
}

const drawWalls = (cornerWalls, roundWalls, bouncyCornerWalls, bouncyRoundWalls) => {
  // Eckige Wände
  const wallContainer = new PIXI.Container()
  wallContainer.zIndex = 100

  cornerWalls.forEach(object => {
    const bodyGraphics = new PIXI.Graphics()
    const body = object.getBody()
    bodyGraphics.beginFill(object.getColor())
    let firstVertex
    body.vertices.forEach((vertex, index) => {
      if (index === 0) {
        firstVertex = vertex
        bodyGraphics.moveTo(vertex.x, vertex.y)
      } else {
        bodyGraphics.lineTo(vertex.x, vertex.y)
      }
    })
    if (firstVertex) {
      // @ts-ignore
      bodyGraphics.lineTo(firstVertex.x, firstVertex.y)
    }
    bodyGraphics.endFill()

    wallContainer.addChild(bodyGraphics)
  })

  // Eckige Bouncy Wände
  wallContainer.zIndex = 100

  bouncyCornerWalls.forEach(object => {
    const bodyGraphics = new PIXI.Graphics()
    const body = object.getBody()
    bodyGraphics.beginFill(0xFFB8A0)
    let firstVertex
    body.vertices.forEach((vertex, index) => {
      if (index === 0) {
        firstVertex = vertex
        bodyGraphics.moveTo(vertex.x, vertex.y)
      } else {
        bodyGraphics.lineTo(vertex.x, vertex.y)
      }
    })
    if (firstVertex) {
      // @ts-ignore
      bodyGraphics.lineTo(firstVertex.x, firstVertex.y)
    }
    bodyGraphics.endFill()

    wallContainer.addChild(bodyGraphics)
  })

  // Runde Wände
  roundWalls.forEach(object => {
    const circle = new PIXI.Graphics()
    const body = object.getBody()
    circle.beginFill(object.getColor())
    circle.drawCircle(body.position.x, body.position.y, body.circleRadius)
    circle.endFill()
    wallContainer.addChild(circle)
  })

  // Eckige Runde Wände
  bouncyRoundWalls.forEach(object => {
    const circle = new PIXI.Graphics()
    const body = object.getBody()
    circle.beginFill(0xFFB8A0)
    circle.drawCircle(body.position.x, body.position.y, body.circleRadius)
    circle.endFill()
    wallContainer.addChild(circle)
  })

  // @ts-ignore
  wallContainer.filters = [new DropShadowFilter({ blur: 1, shadowOnly: false, distance: 4, rotation: 33 }), new BevelFilter({ thickness: 5, lightAlpha: 0, shadowAlpha: 0.4, rotation: 66 })]

  app.stage.addChild(wallContainer)
}

// const drawTriangle = (triangleObjects) => {
//   const triangleContainer = new PIXI.Container()
//   triangleObjects.forEach(object => {
//     const body = object.getBody()
//     const bodyGraphics = new PIXI.Graphics()
//     bodyGraphics.beginFill(object.getColor())
//     let firstVertex
//     body.vertices.forEach((vertex, index) => {
//       if (index === 0) {
//         firstVertex = vertex
//         bodyGraphics.moveTo(vertex.x, vertex.y)
//       } else {
//         bodyGraphics.lineTo(vertex.x, vertex.y)
//       }
//     })
//     if (firstVertex) {
//       // @ts-ignore
//       bodyGraphics.lineTo(firstVertex.x, firstVertex.y)
//     }
//     bodyGraphics.endFill()

//     // @ts-ignore
//     triangleContainer.filters = [new NoiseFilter(0.05)]

//     triangleContainer.addChild(bodyGraphics)
//   })

//   app.stage.addChild(triangleContainer)
// }

import { colors, createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class BouncyWalls extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'BouncyWalls', 6)
    this.assemble()
  }

  assemble () {
    this._addRectangularWall(333, 200, 40, 400, true, true, colors.wall, 0, true)
    this._addRectangularWall(333, 700, 40, 200, true, true, colors.wall, 0, false)
    this._addRectangularWall(666, 600, 40, 400, true, true, colors.wall, 0, true)
    this._addRectangularWall(666, 100, 40, 200, true, true, colors.wall, 0, false)

    this._addRectangularWater(500, 40, 1000, 50)
    this._addRectangularWater(500, 760, 1000, 50)

    // Loch
    this._hole = createHole(833, 650)

    this._ballSpawns = [{ x: 167, y: 150 }]

    this._addRectangularWall(0, 750, 400, 400, true, true, colors.wall, radians[45], true)
    this._addRectangularWall(1000, 200, 200, 200, true, true, colors.wall, radians[45], true)

    this._createBorders()
  }
}

import { createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class MouseGetsCheese extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'MouseGetsCheese', 8)
    this.assemble()
  }

  assemble () {
    // Borders
    this._createBorders()

    // Wände
    // Linke Seite
    // 1
    this._addRectangularWall(440, 175, 40, 300)
    // 2
    this._addRectangularWall(440, 525, 40, 320)
    // 3
    this._addRectangularWall(273, 665, 220, 40)
    // 4
    this._addRectangularWall(145, 480, 40, 410)
    // 5
    this._addRectangularWall(105, 295, 40, 40)
    // 6
    this._addRectangularWall(50, 585, 50, 40)
    // 7
    this._addRectangularWall(360, 500, 120, 40)
    // 8
    this._addRectangularWall(275, 250, 40, 300)
    // 9
    this._addRectangularWall(200, 175, 110, 40)
    // Rechte Seite
    // 10
    this._addRectangularWall(600, 235, 40, 200)
    // 11
    this._addRectangularWall(600, 115, 160, 40)
    // 12
    this._addRectangularWall(800, 150, 40, 250)
    // 13
    this._addRectangularWall(870, 150, 100, 40)
    // 14
    this._addRectangularWall(675, 355, 300, 40)
    // 15
    this._addRectangularWall(825, 460, 40, 250)
    // 16
    this._addRectangularWall(875, 410, 60, 40)
    // 17
    this._addRectangularWall(545, 480, 210, 40)
    // 18
    this._addRectangularWall(600, 600, 40, 200)
    // 19
    this._addRectangularWall(550, 625, 60, 40)
    // 20
    this._addRectangularWall(710, 565, 190, 40)
    // 21
    this._addRectangularWall(945, 600, 60, 40)
    // 22
    this._addRectangularWall(710, 730, 40, 100)
    // 23
    this._addRectangularWall(765, 675, 150, 40)
    // 24
    this._addRectangularWall(850, 685, 40, 60)

    // Wasser
    this._addCircularWater(205, 605, 80)
    this._addCircularWater(75, 75, 80)

    // Sand
    this._addCircularSand(700, 650, 50)
    this._addCircularSand(670, 280, 75)

    // Loch
    this._hole = createHole(484, 741)

    // Start
    this._ballSpawns = [{ x: 484, y: 34 }]
  }
}

import { createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class TunnelingTriangleTest extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'TunnelingTriangleTest', 99)
    this.assemble()
  }

  assemble () {
    // Borders
    this._createBorders()

    // Dreieck
    this._addTriangleWall(500, 500, 80)

    // Loch
    this._hole = createHole(484, 741)

    // Start
    this._ballSpawns = [{ x: 484, y: 34 }]
  }
}

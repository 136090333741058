import { colors, createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class OctagonL extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'OctagonL', 4)
    this.assemble()
  }

  assemble () {
    // Borders
    this._createBorders(false, colors.background)
    // backgroundElementLeftBorder01
    this._addRectangularWall(50, 400, 100, 820, true, true, colors.background)
    // backgroundElementLeftBorder02
    this._addRectangularWall(300, 250, 500, 500, true, true, colors.background)
    // backgroundElementLeftBorder03
    this._addRectangularWall(580, 425, 100, 150, true, true, colors.background)
    // backgroundElementUpperBorder
    this._addRectangularWall(500, 40, 1000, 100, true, true, colors.background)
    // backgroundElementLowerBorder
    this._addRectangularWall(500, 800, 1000, 100, true, true, colors.background)
    // backgroundElementRightBorder01
    this._addRectangularWall(995, 400, 100, 820, true, true, colors.background)
    // backgroundElementRightBorder02
    this._addRectangularWall(950, 600, 100, 450, true, true, colors.background)

    // WATER
    this._addRectangularWater(500, 690, 150, 90)
    this._addRectangularWater(500, 550, 150, 90)

    // OBSTACLE
    this._addRectangularWall(750, 300, 75, 75, true, true, undefined, radians[45], true)
    this._addRectangularWall(800, 650, 75, 75, true, true, colors.lightBlue, radians[45])

    // backgroundElementDiamondLeftBottom
    this._addRectangularWall(550, 350, 100, 100, true, true, colors.background, radians[45])
    // backgroundElementDiamondRightBottom
    this._addRectangularWall(950, 350, 100, 100, true, true, colors.background, radians[45])
    // backgroundElementDiamondLeftBottom
    this._addRectangularWall(550, 80, 100, 100, true, true, colors.background, radians[45])
    // backgroundElementDiamondRightBottom
    this._addRectangularWall(960, 80, 100, 100, true, true, colors.background, radians[45])

    // leftWallBorder
    this._addRectangularWall(90, 625, 20, 250, true, true, colors.wall)
    // rightLWallBorder
    this._addRectangularWall(890, 550, 20, 400, true, true, colors.wall)

    // shorLeftWallBorder
    this._addRectangularWall(620, 425, 20, 150, true, true, colors.wall)
    // shortUpperLeftWall
    this._addRectangularWall(555, 220, 20, 150, true, true, colors.wall)
    // shortUpperRightWall
    this._addRectangularWall(955, 220, 20, 150, true, true, colors.wall)

    // lowerWallBorder
    this._addRectangularWall(500, 740, 800, 20, true, true, colors.wall)
    // upperWallBorder
    this._addRectangularWall(755, 85, 280, 20, true, true, colors.wall)
    // upperLowerWallBorder
    this._addRectangularWall(355, 500, 550, 20, true, true, colors.wall)

    // diagonalLeftBottom
    this._addRectangularWall(588, 322, 100, 20, true, true, colors.wall, radians[45])
    // diagonalRightBottom
    this._addRectangularWall(922, 322, 20, 100, true, true, colors.wall, radians[45])
    // diagonalLeftUpper
    this._addRectangularWall(587, 118, 20, 100, true, true, colors.wall, radians[45])
    // diagonalRightUpper
    this._addRectangularWall(923, 118, 100, 20, true, true, colors.wall, radians[45])

    // Loch
    this._hole = createHole(760, 200)

    // Ball
    this._ballSpawns = [{ x: 150, y: 625 }]
  }
}

import Matter from 'matter-js'

// Es wird bestimmt, ob Circle1 sich in Circle2 befindet
export function determineIfCircle1IsInCircle2 (circle1, circle2, multiplicator) {
  const deltaVector = Matter.Vector.sub({ x: circle2.position.x, y: circle2.position.y }, { x: circle1.position.x, y: circle1.position.y })
  const deltaVectorMagnitude = Matter.Vector.magnitude(deltaVector)
  if (circle1.circleRadius && circle2.circleRadius) {
    if (deltaVectorMagnitude <= (circle2.circleRadius - circle1.circleRadius) * multiplicator) {
      return true
    }
  }
  return false
}

import { colors, createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class ThroneRoom extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'ThroneRoom', 5)
    this.assemble()
  }

  assemble () {
    // Loch
    this._hole = createHole(600, 80)

    this._ballSpawns = [{ x: 70, y: 750 }]

    this._addCircularWall(150, 133, 45, true, true, colors.concrete)
    this._addCircularWall(150, 266, 45, true, true, colors.concrete)
    this._addCircularWall(150, 399, 45, true, true, colors.concrete)
    this._addCircularWall(150, 533, 45, true, true, colors.concrete)
    this._addCircularWall(150, 666, 45, true, true, colors.concrete)

    this._addCircularWall(350, 133, 45, true, true, colors.concrete)
    this._addCircularWall(350, 266, 45, true, true, colors.concrete)
    this._addCircularWall(350, 399, 45, true, true, colors.concrete)
    this._addCircularWall(350, 533, 45, true, true, colors.concrete)
    this._addCircularWall(350, 666, 45, true, true, colors.concrete)

    this._addCircularWall(650, 133, 45, true, true, colors.concrete)
    this._addCircularWall(650, 266, 45, true, true, colors.concrete)
    this._addCircularWall(650, 399, 45, true, true, colors.concrete)
    this._addCircularWall(650, 533, 45, true, true, colors.concrete)
    this._addCircularWall(650, 666, 45, true, true, colors.concrete)

    this._addCircularWall(850, 133, 45, true, true, colors.concrete)
    this._addCircularWall(850, 266, 45, true, true, colors.concrete)
    this._addCircularWall(850, 399, 45, true, true, colors.concrete)
    this._addCircularWall(850, 533, 45, true, true, colors.concrete)
    this._addCircularWall(850, 666, 45, true, true, colors.concrete)

    // Throne
    this._addRectangularWall(500, 100, 150, 130, true, true, colors.wood)
    // Teppich
    this._addCarpet(500, 550, 180, 750)

    // Borders am Ende erstellen damit die oben sind
    this._createBorders(false, colors.concrete)
  }
}

import { createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class UndergroundTest extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'UndergroundTest', 10)
    this.assemble()
  }

  assemble () {
    // Loch
    this._hole = createHole(760, 500)

    this._ballSpawns = [{ x: 240, y: 550 }]

    // Wasser
    this._addRectangularWater(900, 700, 200, 200)
    this._addRectangularSand(100, 100, 200, 200)
    this._addRectangularIce(600, 100, 600, 200)

    // Borders am Ende erstellen damit die oben sind
    this._createBorders()
  }
}

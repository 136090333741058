import Matter from 'matter-js'
import randomColor from 'randomcolor'
import { settings } from '../settings'
import { generateForceVector } from '../utils/forceUtils'
import { getClients, initClientEngine, spawnClientBall } from './clientEngine'
import { initRenderer } from './renderer'

const numberOfBalls = 10
let interval

export const startSplashScreen = () => {
  initClientEngine('SplashScreenMap', true)
  initRenderer(true)

  for (let i = 0; i < numberOfBalls; i++) {
    spawnClientBall(i, randomColor())
  }

  const clients = getClients()
  interval = setInterval(() => {
    const randomClient = clients[Math.floor(Math.random() * numberOfBalls)]
    const pos = { x: Math.floor(Math.random() * 1000), y: Math.floor(Math.random() * 800) }

    const forceVector = generateForceVector(pos, randomClient.getBody().position, settings.maxForce / 5, 400)
    Matter.Body.applyForce(randomClient.getBody(), randomClient.getBody().position, forceVector)
  }, 500)
}

export const stopSplashScreen = () => {
  clearInterval(interval)
}

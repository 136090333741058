import React from 'react'
import ReactDOM from 'react-dom'
import { registerMaps } from '../maps/mapRegistry.js'
import { App } from './App.js'
import { initBrowserEventListeners } from './browserInteraction.js'
import { initClientCommunication } from './clientCommunication.js'
import { startSplashScreen } from './splashScreen.js'

/**
 * Einstieg in das Client-Spiel. Wird von der Index.html aufgerufen.
 * Initialisiert alle weiteren Module.
 */

initClientCommunication()

registerMaps()

startSplashScreen()

initBrowserEventListeners()

ReactDOM.render(<App></App>, document.getElementById('root'))

import { createGlobalState } from 'react-hooks-global-state'

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
  stage: 'registerName',
  time: 0,
  players: []
})

export const setStage = (stageName) => {
  setGlobalState('stage', stageName)
}

export const getStage = () => {
  return getGlobalState('stage')
}

export const setTime = (secondsLeft) => {
  setGlobalState('time', secondsLeft)
}

export const getTime = () => {
  return getGlobalState('time')
}

export const setAllPlayers = (allPlayers) => {
  setGlobalState('players', allPlayers)
}

export const getAllPlayers = () => {
  return getGlobalState('players')
}

export { useGlobalState }

import { LMap } from './LMap.js'
import { DockInwards } from './DockInwards.js'
import { TunnelingTest } from './TunnelingTest.js'
import { UndergroundTest } from './UndergroundTest.js'
import { IceBath } from './IceBath.js'
import { TiltedSquares } from './TiltedSquares.js'
import { IcyS } from './IcyS.js'
import { Cross } from './Cross.js'
import { MouseGetsCheese } from './MouseGetsCheese.js'
import { TunnelingTriangleTest } from './TunnelingTriangleTest.js'
import { Diagonals } from './Diagonals.js'
import { WoodenWall01 } from './WoodenWall01.js'
import { ThroneRoom } from './ThroneRoom.js'
import { BouncyWalls } from './BouncyWalls.js'
import { BouncyDiamond } from './BouncyDiamond.js'
import { SplashScreenMap } from './SplashScreenMap.js'
import { Fibo } from './Fibo.js'
import { PocketTest } from './PocketTest.js'
import { TShape } from './TShape.js'
import { OctagonL } from './OctagonL.js'
import { Only90sKidsWillRemember } from './Only90sKidsWillRemember.js'
import { OhNoNoNo } from './OhNoNoNo.js'
import { Believe } from './Believe.js'
import { Moon } from './Moon.js'
import { HMap } from './HMap.js'

/**
 * Die Idee dieses Moduls ist es, Maps dynamisch zur Laufzeit zu laden, anstatt alle Maps als
 * Objekte immer im Speicher zu halten. Die Klassen der Maps werden in registerMaps hinterlegt.
 * Wenn dann die Map geladen werden soll, wird mit durch Reflexion das Objekt zur Laufzeit erzeugt.
 * In der Zukunft könnte man dem Client das JS der Map zuschicken, anstatt alle Klassen zu
 * hinterlegen. Oder Maps in ein Format bringen was man als JSON übertragen kann.
 */

const maps = []

/**
 * Registriert alle hinterlegten Map-Klassen in maps-Array
 */
export const registerMaps = () => {
  maps.push({ name: 'TunnelingTriangleTest', mapObject: TunnelingTriangleTest })
  maps.push({ name: 'MouseGetsCheese', mapObject: MouseGetsCheese })
  maps.push({ name: 'Believe', mapObject: Believe })
  maps.push({ name: 'Cross', mapObject: Cross })
  maps.push({ name: 'Diagonals', mapObject: Diagonals })
  maps.push({ name: 'DockInwards', mapObject: DockInwards })
  maps.push({ name: 'IceBath', mapObject: IceBath })
  maps.push({ name: 'IcyS', mapObject: IcyS })
  maps.push({ name: 'Moon', mapObject: Moon })
  maps.push({ name: 'OhNoNoNo', mapObject: OhNoNoNo })
  maps.push({ name: 'Only90sKidsWillRemember', mapObject: Only90sKidsWillRemember })
  maps.push({ name: 'LMap', mapObject: LMap, development: true })
  maps.push({ name: 'ThroneRoom', mapObject: ThroneRoom })
  maps.push({ name: 'TiltedSquares', mapObject: TiltedSquares })
  maps.push({ name: 'TunnelingTest', mapObject: TunnelingTest, development: true })
  maps.push({ name: 'UndergroundTest', mapObject: UndergroundTest, development: true })
  maps.push({ name: 'WoodenWall01', mapObject: WoodenWall01 })
  maps.push({ name: 'BouncyWalls', mapObject: BouncyWalls })
  maps.push({ name: 'BouncyDiamond', mapObject: BouncyDiamond })
  maps.push({ name: 'SplashScreenMap', mapObject: SplashScreenMap, isSplashScreen: true })
  maps.push({ name: 'Fibo', mapObject: Fibo })
  maps.push({ name: 'PocketTest', mapObject: PocketTest, development: true })
  maps.push({ name: 'TShape', mapObject: TShape })
  maps.push({ name: 'OctagonL', mapObject: OctagonL })
  maps.push({ name: 'HMap', mapObject: HMap })
}

/**
 * Erzeugt ein Map-Object zur Laufzeit, nach Name
 * @param {*} name Name der Map die geladen werden soll. Name sollte mit _name Attribut der Klasse
 * übereinstimmen
 * @param {*} isClientMap Boolean welcher bestimmt ob es eine Client oder Server Map sein soll
 * @returns Map Objekt nach Namen
 */
export const createMap = (name, isClientMap) => {
  for (const map of maps) {
    if (map.name === name) {
      return Reflect.construct(map.mapObject, [isClientMap])
    }
  }
}

export const getRandomMapName = () => {
  const pool = maps.filter(map => (
    map.development !== true ||
    process.env.NODE_ENV?.includes('development')) &&
    map.isSplashScreen !== true)

  const randomMap = pool[Math.floor(Math.random() * pool.length)]
  return randomMap.name
}

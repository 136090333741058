import { colors, createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class BouncyDiamond extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'BouncyDiamond', 4)
    this.assemble()
  }

  assemble () {
    this._addRectangularWater(150, 150, 300, 300)
    this._addRectangularWater(150, 650, 300, 300)
    this._addRectangularWater(850, 130, 300, 260)
    this._addRectangularWater(850, 670, 300, 260)

    this._addRectangularWall(500, 400, 195, 195, true, true, colors.wall, radians[45], true)

    this._addRectangularWall(850, 280, 300, 40, true, true)
    this._addRectangularWall(850, 520, 300, 40, true, true)

    // Loch
    this._hole = createHole(920, 400)

    this._ballSpawns = [{ x: 80, y: 400 }]
    this._createBorders()
  }
}

import React, { useContext, useState } from 'react'
import GameContext from '../context/GameContext'
import { emitLeave, emitNextMap, getClientSocketId } from '../clientCommunication.js'
import './MainMenu.css'
import { PlayerInfo } from './PlayerInfo'

export function AfterRound () {
  const ctx = useContext(GameContext)
  const [nextMap, setNextMap] = useState('')

  function nextStage () {
    let mapName = nextMap
    if (mapName === '') {
      mapName = 'RandomMap'
    }
    emitNextMap(mapName)
  }
  function leaveStage () {
    emitLeave()
    ctx.setStage('mainMenu')
  }

  return <>
    <div className="mainMenu">{ctx.players[0].socketId === getClientSocketId() &&
      <><button onClick={() => nextStage()} className="menuButton">Start</button>
        <select defaultValue="RandomMap" onChange={(e) => setNextMap(e.target.value)} className='mapSelectLobbyButton'>
          <option value="RandomMap">Random Map</option>
          <option value="Believe">Believe</option>
          <option value="BouncyDiamond">BouncyDiamond</option>
          <option value="BouncyWalls">BouncyWalls</option>
          <option value="Cross">Cross</option>
          <option value="MouseGetsCheese">MouseGetsCheese</option>
          <option value="Diagonals">Diagonals</option>
          <option value="DockInwards">DockInwards</option>
          <option value="Fibo">Fibo</option>
          <option value="HMap">HMap</option>
          <option value="OctagonL">OctagonL</option>
          <option value="IceBath">IceBath</option>
          <option value="IcyS">IcyS</option>
          <option value="Moon">Moon</option>
          <option value="OhNoNoNo">OhNoNoNo</option>
          <option value="Only90sKidsWillRemember">Only90sKidsWillRemember</option>
          <option value="ThroneRoom">ThroneRoom</option>
          <option value="TiltedSquares">TiltedSquares</option>
          <option value="TShape">TShape</option>
          <option value="WoodenWall01">WoodenWall01</option>
          {
            // eslint-disable-next-line multiline-ternary
            process.env.NODE_ENV?.includes('development') ? <>
              <option value="LMap">LMap</option>
              <option value="PocketTest">PocketTest</option>
              <option value="TunnelingTest">TunnelingTest</option>
              <option value="UndergroundTest">UndergroundTest</option>
              <option value="TunnelingTriangleTest">TunnelingTriangleTest</option>
            </> : <></>
          }
        </select></>
    }

      <button onClick={() => leaveStage()} className="menuButton">Leave</button>
    </div>
    <PlayerInfo></PlayerInfo>
  </>
}

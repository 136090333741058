import { createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class IcyS extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'IcyS', 4)
    this.assemble()
  }

  assemble () {
    // Ice
    this._addRectangularIce(500, 400, 1000, 420)

    // Wasser
    this._addRectangularWater(250, 240, 550, 100)
    this._addRectangularWater(750, 560, 550, 100)

    // Loch
    this._hole = createHole(920, 720)

    this._ballSpawns = [{ x: 80, y: 80 }]

    // Borders am Ende erstellen damit die oben sind
    this._createBorders()
  }
}

import { colors, createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

/**
 * Original LMap des Hackatons auf es6 Klasse übersetzt
 *
 * @class LMap
 * @author Barg Leon
 */
export class LMap extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'LMap', 3)
    this.assemble()
  }

  assemble () {
    // lowerWallInvisble
    this._addRectangularWall(340, 560, 540, 300, true, true, colors.background)
    // upperWallInvisble
    this._addRectangularWall(500, 95, 1000, 190, true, true, colors.background)
    // leftLWallInvisble
    this._addRectangularWall(50, 400, 100, 800, true, true, colors.background)
    // rightWallInvisble
    this._addRectangularWall(950, 400, 100, 800, true, true, colors.background)
    // lowerLWallInvisble
    this._addRectangularWall(500, 760, 1000, 120, true, true, colors.background)

    // leftWall
    this._addRectangularWall(110, 300, 20, 200, true, true, colors.wall)
    // lowerWall
    this._addRectangularWall(350, 410, 500, 20, true, true, colors.wall)
    // upperWall
    this._addRectangularWall(500, 190, 800, 20, true, true, colors.wall)
    // leftLWall
    this._addRectangularWall(600, 550, 20, 300, true, true, colors.wall)
    // rightWall
    this._addRectangularWall(890, 450, 20, 500, true, true, colors.wall)
    // lowerLWall
    this._addRectangularWall(745, 700, 310, 20, true, true, colors.wall)

    // Loch
    this._hole = createHole(745, 600)
  }
}

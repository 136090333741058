import { colors, createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class TShape extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'TShape', 3)
    this.assemble()
  }

  assemble () {
    // Borders
    this._createBorders(false, colors.background)

    // upperWallBorder
    this._addRectangularWall(500, 30, 1000, 20, true, true, colors.background)
    // leftupperWall
    this._addRectangularWall(115, 205, 210, 330, true, true, colors.background)
    // rightUpperWall
    this._addRectangularWall(885, 205, 210, 330, true, true, colors.background)

    // leftShortWall
    this._addRectangularWall(270, 360, 100, 20, true, true, colors.background)
    // rightShortWall
    this._addRectangularWall(730, 360, 100, 20, true, true, colors.background)

    // leftLowerWall
    this._addRectangularWall(165, 560, 310, 400, true, true, colors.background)
    // rightLowerWall
    this._addRectangularWall(835, 560, 310, 400, true, true, colors.background)

    // lowerWall
    this._addRectangularWall(500, 770, 1000, 20, true, true, colors.background)

    // leftLowerObstacle
    this._addRectangularWall(465, 500, 40, 200, true, true, colors.yellow)
    // rightLowerObstacle
    this._addRectangularWall(535, 500, 40, 200, true, true, colors.yellow)

    // upperObstacle
    this._addRectangularWall(475, 130, 100, 100, true, true, colors.lightBlue, radians[45])

    // upperWallBorder
    this._addRectangularWall(500, 30, 600, 20, true, true, colors.wall)

    // leftupperWallBorder
    this._addRectangularWall(210, 205, 20, 330, true, true, colors.wall)
    // rightUpperWallBorder
    this._addRectangularWall(790, 205, 20, 330, true, true, colors.wall)

    // leftShortWallBorder
    this._addRectangularWall(270, 360, 100, 20, true, true, colors.wall)
    // rightShortWallBorder
    this._addRectangularWall(730, 360, 100, 20, true, true, colors.wall)

    // leftLowerWallBorder
    this._addRectangularWall(310, 560, 20, 400, true, true, colors.wall)
    // rightLowerWallBorder
    this._addRectangularWall(690, 560, 20, 400, true, true, colors.wall)

    // lowerWallBorder
    this._addRectangularWall(500, 770, 400, 20, true, true, colors.wall)

    // Loch
    this._hole = createHole(730, 180)

    // Ball
    this._ballSpawns = [{ x: 500, y: 740 }]
  }
}

import { colors, createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class WoodenWall01 extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'WoodenWall01', 5)
    this.assemble()
  }

  assemble () {
    // Loch
    this._hole = createHole(900, 90)

    this._ballSpawns = [{ x: 150, y: 725 }]

    // Wooden Walls
    this._addRectangularWall(300, 650, 40, 600, true, true, colors.wood)
    this._addRectangularWall(700, 300, 40, 650, true, true, colors.wood)

    this._addRectangularWall(900, 180, 200, 40, true, true, colors.wood)

    // Sand
    this._addRectangularSand(340, 100, 680, 200)

    // Borders am Ende erstellen damit die oben sind
    this._createBorders(false, colors.wood)
  }
}

import { colors, createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

/**
 * Map für den Splashscreen
 *
 * @class SplashScreenMap
 */
export class SplashScreenMap extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'SplashScreenMap', 10)
    this.assemble()
  }

  assemble () {
    // Linke Border
    this._addRectangularWall(0, 400, 50, 800, true, true, colors.wall)
    // Rechte Border
    this._addRectangularWall(1000, 400, 50, 800, true, true, colors.wall)
    // Obere Border
    this._addRectangularWall(500, 0, 1000, 50, true, true, colors.wall)
    // Untere Border
    this._addRectangularWall(500, 800, 1000, 50, true, true, colors.wall)

    const randomBallSpawns = []

    for (let i = 0; i < 20; i++) {
      randomBallSpawns.push({
        x: Math.random() * 1000,
        y: Math.random() * 800
      })
    }

    this._ballSpawns = randomBallSpawns

    // Loch
    this._hole = createHole(610, 215)
  }
}

import Matter from 'matter-js'
import { settings } from '../settings.js'
import { determineIfCircle1IsInCircle2 } from './gameUtils.js'

const Bodies = Matter.Bodies

const noBallCollisionFilter = { category: 0, mask: 1, group: 1 }
const ballCollisionFilter = { category: 0, mask: 2, group: 2 }
const collisionFilterWater = { category: 0, mask: 2, group: 2 }
const collisionFilterSand = { category: 0, mask: 2, group: 2 }
const collisionFilterIce = { category: 0, mask: 2, group: 2 }
const lineWidthInvisibility = settings.mapDebuggingActive ? 1 : 0

export const colors = {
  wall: '0xD4D4D4',
  border: '0xD4D4D4',
  water: '0x4F8CFF',
  sand: '0xFFEBA3',
  ice: '0xB5EAFF',
  grass: '0x4DA238',
  concrete: '0xD3C8C8',
  metal: '0xCFD4D9',
  wood: '0x502900',
  carpet: '0xC21616',
  hole: '0x000000',
  bouncy: '0xFFB8A0',
  white: '0xFFFFFF',
  background: '0x2d333f',
  lightBlue: '0x89cff0',
  yellow: '0xFFFCC9',
  red: '0xFF7779'
}

export const radians = {
  270: 4.7123889804,
  180: 3.1415926536,
  135: 2.3561944902,
  90: 1.5707963268,
  45: 0.7853981634,
  0: 0.0
}

export const createHole = (x, y) => {
  return Bodies.circle(x, y, 18, {
    isStatic: true,
    plugin: {
      attractors: [
        function (bodyA, bodyB) {
          if (determineIfCircle1IsInCircle2(bodyB, bodyA, 4)) {
            return {
              x: (bodyA.position.x - bodyB.position.x) * 200e-6,
              y: (bodyA.position.y - bodyB.position.y) * 200e-6
            }
          } else {
            return null
          }
        }
      ]
    },
    label: 'hole',
    render: { fillStyle: '#000000', lineWidth: 0 },
    frictionAir: 0.01,
    restitution: 0.8
  })
}

export const createWall = (x, y, width, height, visibleWall, collideWithBall, color, angle = 0, bouncy = false) => {
  if (visibleWall) {
    return Bodies.rectangle(
      x,
      y,
      width,
      height,
      {
        isStatic: true,
        label: bouncy ? 'bouncy rectangle' : 'Rectangle Body',
        render: {
          fillStyle: color,
          lineWidth: 0
        },
        collisionFilter: collideWithBall ? ballCollisionFilter : noBallCollisionFilter,
        angle: angle
      })
  } else {
    return Bodies.rectangle(
      x,
      y,
      height,
      width,
      {
        isStatic: true,
        render: {
          fillStyle: color,
          lineWidth: lineWidthInvisibility,
          visible: settings.mapDebuggingActive
        },
        collisionFilter: collideWithBall ? ballCollisionFilter : noBallCollisionFilter,
        angle: angle
      })
  }
}

export const createRoundWall = (x, y, radius, visibleWall, collideWithBall, color, bouncy = false) => {
  if (visibleWall) {
    return Bodies.circle(x, y, radius, {
      isStatic: true,
      label: bouncy ? 'bouncy rectangle' : 'Circle Body',
      render: {
        fillStyle: color,
        lineWidth: 0
      },
      collisionFilter: collideWithBall ? ballCollisionFilter : noBallCollisionFilter
    })
  } else {
    return Bodies.circle(x, y, radius, {
      isStatic: true,
      render: {
        fillStyle: color,
        lineWidth: lineWidthInvisibility,
        visible: settings.mapDebuggingActive
      },
      collisionFilter: collideWithBall ? ballCollisionFilter : noBallCollisionFilter
    })
  }
}

export const createWater = (x, y, width, height, angle = 0) => {
  return Bodies.rectangle(
    x,
    y,
    width,
    height,
    {
      isStatic: true,
      render: {
        fillStyle: colors.water
      },
      collisionFilter: collisionFilterWater,
      label: 'water',
      isSensor: true,
      angle: angle
    }
  )
}

export const createRoundWater = (x, y, radius) => {
  return Bodies.circle(x, y, radius, {
    isStatic: true,
    render: {
      fillStyle: colors.water
    },
    collisionFilter: collisionFilterWater,
    label: 'water',
    isSensor: true
  })
}

export const createRoundSand = (x, y, radius) => {
  return Bodies.circle(x, y, radius, {
    isStatic: true,
    render: {
      fillStyle: colors.sand
    },
    collisionFilter: collisionFilterSand,
    label: 'sand',
    isSensor: true
  })
}

export const createRoundIce = (x, y, radius) => {
  return Bodies.circle(x, y, radius, {
    isStatic: true,
    render: {
      fillStyle: colors.ice
    },
    collisionFilter: collisionFilterIce,
    label: 'ice',
    isSensor: true
  })
}

export const createSand = (x, y, width, height) => {
  return Bodies.rectangle(
    x,
    y,
    width,
    height,
    {
      isStatic: true,
      render: {
        fillStyle: colors.sand
      },
      collisionFilter: collisionFilterSand,
      label: 'sand',
      isSensor: true
    }
  )
}

export const createCarpet = (x, y, width, height) => {
  return Bodies.rectangle(
    x,
    y,
    width,
    height,
    {
      isStatic: true,
      render: {
        fillStyle: colors.carpet
      },
      label: 'carpet',
      isSensor: true
    }
  )
}

export const createIce = (x, y, width, height) => {
  return Bodies.rectangle(
    x,
    y,
    width,
    height,
    {
      isStatic: true,
      render: {
        fillStyle: colors.ice
      },
      collisionFilter: collisionFilterIce,
      label: 'ice',
      isSensor: true
    }
  )
}

export const createTriangle = (x, y, radius, visibleWall, collideWithBall, color, angle = 0, bouncy = false) => {
  if (visibleWall) {
    return Bodies.polygon(
      x,
      y,
      3,
      radius,
      {
        isStatic: true,
        label: bouncy ? 'bouncy triangle' : 'Triangle Body',
        render: {
          fillStyle: color,
          lineWidth: 0
        },
        collisionFilter: collideWithBall ? ballCollisionFilter : noBallCollisionFilter,
        angle: angle
      })
  } else {
    return Bodies.polygon(
      x,
      y,
      3,
      radius,
      {
        isStatic: true,
        render: {
          fillStyle: color,
          lineWidth: lineWidthInvisibility,
          visible: settings.mapDebuggingActive
        },
        collisionFilter: collideWithBall ? ballCollisionFilter : noBallCollisionFilter,
        angle: angle
      })
  }
}

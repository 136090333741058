import Matter, { Vector } from 'matter-js'
import { Client } from '../entities/Client.js'
import { createMap } from '../maps/mapRegistry.js'
import { settings } from '../settings.js'
import { cleanUpRenderer, drawNewBall, removeBallGraphic } from './renderer.js'

const Engine = Matter.Engine
const Composite = Matter.Composite
const Body = Matter.Body

let engine
let clients = []
let map
let velocityValues = []
let velocityDif = []
let isSplashScreen

/**
 * Initialisiert die Matterjs Render-Engine.
 * Bodies sind statisch und Positionen werden vom Server geupdated.
 */
export const initClientEngine = (mapName, isSplashScreenValue = false) => {
  velocityValues = []
  velocityDif = []
  map = createMap(mapName, true)
  engine = Engine.create(undefined, settings.engineOptions)
  engine.gravity.y = 0
  const bodies = []
  map.getObjects().forEach(object => bodies.push(object.getBody()))
  Composite.add(engine.world, bodies)
  isSplashScreen = isSplashScreenValue
}

export const updateClientEngine = (deltaTime) => {
  Engine.update(engine, deltaTime)
  clients.forEach((client) => {
    client.updateGraphicPosition()
  })
}

export const getClientMap = () => {
  return map
}

export const spawnClientBall = (index, color) => {
  let body
  if (settings.clientSidePredictionActive || isSplashScreen) {
    body = map.createBall(index, false, color)
  } else {
    body = map.createBall(index, true, color)
  }
  Composite.add(engine.world, body)

  clients.push(new Client(index, body, drawNewBall(color)))
}

export const resetClient = () => {
  clients.forEach(client => {
    if (client) {
      removeClientBall(client.getId())
    }
  })
  clients = []
  cleanUpRenderer()
}

/**
 * Nimmt ein Position-Objekt an was alle Positions der Bälle enthält und überschreibt diese.
 * @param {*} pos Position-Objekt
 */
export const positionUpdate = (pos) => {
  pos.forEach((ballPos, index) => {
    if (ballPos && ballPos !== null) {
      Body.setPosition(clients[index].getBody(), ballPos)
    }
  })
}

export const velocityUpdate = (velocity) => {
  velocity.forEach((velocity, index) => {
    if (velocity && velocity !== null) {
      const body = clients[index].getBody()
      if (body) {
        // Create Velocity Graph
        if (settings.teachClientSidePrediction) {
          if (velocity.velocity.x > 0 || velocity.velocity.y > 0) {
            const newVel = Vector.magnitude(velocity.velocity)
            velocityValues.push(newVel)
            velocityDif.push(Vector.magnitude(body.velocity) - newVel)
          } else {
            if (velocityValues.length > 0) {
              velocityValues = []
              velocityDif = []
            }
          }
        }
        // Lineare Funktion mit maxSpeed berechnen
        let factor
        if (settings.variableFactorActive) {
          const maxFactor = 1.0
          const minFactor = 0.5
          const m = (minFactor - maxFactor) / settings.maxSpeed
          factor = m * (Vector.magnitude(velocity.velocity)) + maxFactor
        } else {
          factor = settings.nonVariableFactor
        }

        Body.setAngle(body, velocity.angle)
        Body.setVelocity(body, { x: velocity.velocity.x * factor, y: velocity.velocity.y * factor })
        Body.setAngularVelocity(body, velocity.angularVelocity * factor)
      }
    }
  })
}

export const removeClientBall = (ballIndex) => {
  const clientToRemove = clients.find(client => {
    if (client) {
      return Number(client.getId()) === ballIndex
    } else {
      return false
    }
  })
  if (clientToRemove) {
    Composite.remove(engine.world, clientToRemove.getBody())
  } else {
    throw new Error(`Cannot find ball with index ${ballIndex} to remove.`)
  }

  removeBallGraphic(clientToRemove.getGraphic())

  delete clients[clients.indexOf[clientToRemove]]
}

export const getClients = () => {
  return clients
}

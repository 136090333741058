import React from 'react'

export function LobbyCode (props) {
  return <>
    <div className="socketIdFlex">
      <p className="menuText">Lobby: {props.lobbyId}</p>
      <div onClick={() => navigator.clipboard.writeText(props.lobbyId)} className='copyButton'><span>&nbsp;</span>📋</div>
    </div>
  </>
}

import { io } from 'socket.io-client'
import { settings } from '../settings.js'
import { initClientEngine, positionUpdate, removeClientBall, resetClient, spawnClientBall, velocityUpdate } from './clientEngine.js'
import { getAllPlayers, getStage, setAllPlayers, setStage, setTime } from './clientState.js'
import { hideMousePointer, initRenderer, showMousePointer } from './renderer.js'
import { stopSplashScreen } from './splashScreen.js'

let socket
let socketId
let currentPlayerSocketId

/**
 * Initialisiert die socket-io Verbindung.
 * Wenn die Verbindung funktioniert werden die Event-Listener abonniert
 */
export const initClientCommunication = () => {
  if (process.env.NODE_ENV?.includes('production')) {
    socket = io('https://www.migolf.io:443')
  } else {
    socket = io('http://localhost:3000')
  }

  if (socket) {
    listenToSocketEvents(socket)
  } else {
    console.error('Cannot connect websocket')
  }
}

/**
 * Initialisiert die Event-Listener von socket-io
 * @param {*} socket Socket
 */
export const listenToSocketEvents = (socket) => {
  socket.on('posUpdate', (posUpdate) => {
    if (settings.clientSidePredictionActive) {
      velocityUpdate(posUpdate.velocity)
    }
    positionUpdate(posUpdate.posUpdate)
  })

  socket.on('timerUpdate', (secondsLeft) => {
    setTime(secondsLeft)
  })

  socket.on('spawn', (spawnObject) => {
    spawnClientBall(spawnObject.ballIndex, spawnObject.color)
  })

  socket.on('delete', (ballIndex) => {
    removeClientBall(ballIndex)
  })

  socket.on('connect', () => {
    socketId = socket.id
  })

  socket.on('joinLobbyFeedback', (feedback) => {
    if (feedback && feedback.type) {
      switch (feedback.type) {
        case 'joined': {
          setStage('waitingLobby')
          break
        }
      }
    }
  })

  socket.on('clientUpdate', (clients) => {
    const allPlayers = getAllPlayers()
    const cleanLobbyMembers = clients.filter(member => member)
    // Spieler die das Spiel verlassen haben rausfiltern
    let updatedAllPlayers = allPlayers.filter(player => {
      let found = false
      cleanLobbyMembers.forEach(member => {
        if (member.socketId === player.socketId) {
          found = true
        }
      })
      return found
    })
    // Spieler die hinzugekommen sind hinzufügen
    cleanLobbyMembers.filter(member => {
      let found = true
      updatedAllPlayers.forEach(player => {
        if (player.socketId === member.socketId) {
          found = false
        }
      })
      return found
    }).forEach(newPlayer => {
      updatedAllPlayers.push({ name: newPlayer.name, socketId: newPlayer.socketId })
    })

    // Nach Id sortieren damit Reihenfolge für Lobbyersteller (erster im Array) gleich bleibt
    updatedAllPlayers = updatedAllPlayers.sort((a, b) => {
      if (a.id > b.id) {
        return 1
      } else {
        return -1
      }
    })

    setAllPlayers(updatedAllPlayers)
  })

  socket.on('gameStart', (startObject) => {
    stopSplashScreen()
    currentPlayerSocketId = startObject.currentPlayerSocketId
    resetClient()
    setStage('gameRunning')
    initClientEngine(startObject.map)
    initRenderer()
    if (isCurrentPlayer()) {
      showMousePointer(startObject.currentPlayerIndex)
    }
  })

  socket.on('allPlayers', (allPlayers) => {
    console.log('emit allPlaysers', allPlayers)
    setAllPlayers(allPlayers)
  })

  socket.on('turnStarted', (turnStartedObject) => {
    hideMousePointer()
    currentPlayerSocketId = turnStartedObject.currentPlayerSocketId
    if (isCurrentPlayer()) {
      showMousePointer(turnStartedObject.currentPlayerIndex)
    }
  })

  socket.on('gameEnded', (allPlayers) => {
    hideMousePointer()
    setAllPlayers(allPlayers)
    setStage('afterRound')
  })
}

export const getClientSocketId = () => {
  return socketId
}

export const handleHit = (pos) => {
  if (isCurrentPlayer() && getStage() === 'gameRunning') {
    socket.emit('hit', { x: pos.x, y: pos.y })
    hideMousePointer()
  }
}

export const emitCreateLobby = (lobbySettings) => {
  socket.emit('createLobby', lobbySettings)
}

export const emitJoinLobby = (lobby) => {
  socket.emit('joinLobby', lobby)
}

export const emitNextMap = (map) => {
  socket.emit('nextMap', map)
}

export const emitRegisterName = (name) => {
  socket.emit('registerName', name)
}

export const emitLeave = () => {
  socket.emit('leave')
}

export const isCurrentPlayer = () => {
  return currentPlayerSocketId === socketId
}

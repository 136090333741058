import React, { useContext } from 'react'
import GameContext from '../context/GameContext'
import { AfterRound } from './AfterRound'
import './GameOverlay.css'
import { GameRunning } from './GameRunning'
import { LobbySettings } from './LobbySettings'
import { MainMenu } from './MainMenu'
import { RegisterName } from './RegisterName'
import { WaitingLobby } from './WaitingLobby'

export function GameOverlay () {
  const ctx = useContext(GameContext)

  if (ctx.stage === 'gameRunning' || ctx.stage === 'waitingLobby') {
    return <>
      <div className="inGameOverlay">
        {ctx.stage === 'gameRunning' ? <GameRunning></GameRunning> : <WaitingLobby></WaitingLobby>}
      </div>
    </>
  } else {
    return <>
      <div className="menuOverlay">
        {
          ctx.stage === 'registerName' &&
          <RegisterName></RegisterName>
        }
        {
          ctx.stage === 'mainMenu' &&
          <MainMenu></MainMenu>
        }
        {
          ctx.stage === 'lobbySettings' &&
          <LobbySettings></LobbySettings>
        }
        {
          ctx.stage === 'afterRound' &&
          <AfterRound></AfterRound>
        }
      </div>
    </>
  }
}

import { colors, createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class TunnelingTest extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'TunnelingTest', 10)
    this.assemble()
  }

  assemble () {
    // Dünn
    // Useless
    // this._addRectangularWall(500, 480, 10, 200, true, true, colors.wall)
    this._addRectangularWall(300, 400, 30, 800, true, true, colors.wall)
    // this._addRectangularWall(500, 350, 1000, 50, true, true, colors.wall)

    // Dicker
    // Bei Maxforce 0.06 nicht durchdringbar
    // this._addRectangularWall(300, 480, 40, 200, true, true, colors.wall)

    // Loch
    this._hole = createHole(800, 400)

    this._ballSpawns = [{ x: 100, y: 400 }]

    // Borders am Ende erstellen damit die oben sind
    this._createBorders()
  }
}

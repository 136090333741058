import React, { useState } from 'react'
import GameContext from './context/GameContext.js'
import { getClientSocketId } from './clientCommunication.js'
import { GameOverlay } from './components/GameOverlay.js'
import { useGlobalState } from './clientState.js'

export function App () {
  const [stage, setStage] = useGlobalState('stage')
  const [players, setPlayers] = useGlobalState('players')
  const [time, setTime] = useGlobalState('time')
  const [socketId, setSocketId] = useState(getClientSocketId())

  const context = {
    stage: stage,
    setStage: setStage,
    socketId: socketId,
    setSocketId: setSocketId,
    players: players,
    setPlayers: setPlayers,
    setTime: setTime,
    time: time
  }
  return <GameContext.Provider value={context}>
    <GameOverlay></GameOverlay>
  </GameContext.Provider>
}

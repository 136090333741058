import { createHole, radians } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class OhNoNoNo extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'OhNoNoNo', 6)
    this.assemble()
  }

  assemble () {
    // Wasser
    this._addRectangularWater(625, 250, 750, 100)

    this._addRectangularWall(0, 0, 300, 300, true, true, undefined, radians[45], true)
    this._addRectangularWall(1000, 800, 300, 300, true, true, undefined, radians[45], true)
    this._addRectangularWall(0, 800, 300, 300, true, true, undefined, radians[45], true)
    this._addCircularWall(250, 250, 50)

    // Loch
    this._hole = createHole(750, 550)

    this._ballSpawns = [{ x: 887.5, y: 112.5 }]

    // Borders am Ende erstellen damit die oben sind
    this._createBorders(true)
  }
}

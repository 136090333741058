import React, { useContext, useState } from 'react'
import { getClientSocketId, emitCreateLobby } from '../clientCommunication'
import GameContext from '../context/GameContext'
import { LobbyCode } from './LobbyCode'
import './MainMenu.css'

export function LobbySettings () {
  const [maxPlayers, setMaxPlayers] = useState('')
  const [maxTime, setTime] = useState('')
  const [map, setMap] = useState('')
  const lobbyStartSettings = {
    map: map,
    maxPlayers: maxPlayers,
    maxTime: maxTime
  }
  const ctx = useContext(GameContext)
  ctx.socketId = getClientSocketId()

  function nextStage () {
    if (lobbyStartSettings.map === '') {
      lobbyStartSettings.map = 'RandomMap'
    }
    if (lobbyStartSettings.maxPlayers !== '' && lobbyStartSettings.maxTime !== '') {
      emitCreateLobby(lobbyStartSettings)
    }
  }
  function lastStage () {
    ctx.setStage('mainMenu')
  }
  return <>
    <div className="mainMenu">
      <button onClick={() => nextStage()} className="menuButton">Start</button>
      <select defaultValue="RandomMap" onChange={(e) => setMap(e.target.value)} className='mapSelectLobbyButton'>
        <option value="RandomMap">Random Map</option>
        <option value="Believe">Believe</option>
        <option value="BouncyDiamond">BouncyDiamond</option>
        <option value="BouncyWalls">BouncyWalls</option>
        <option value="Cross">Cross</option>
        <option value="MouseGetsCheese">MouseGetsCheese</option>
        <option value="Diagonals">Diagonals</option>
        <option value="DockInwards">DockInwards</option>
        <option value="Fibo">Fibo</option>
        <option value="HMap">HMap</option>
        <option value="OctagonL">OctagonL</option>
        <option value="IceBath">IceBath</option>
        <option value="IcyS">IcyS</option>
        <option value="Moon">Moon</option>
        <option value="OhNoNoNo">OhNoNoNo</option>
        <option value="Only90sKidsWillRemember">Only90sKidsWillRemember</option>
        <option value="ThroneRoom">ThroneRoom</option>
        <option value="TiltedSquares">TiltedSquares</option>
        <option value="TShape">TShape</option>
        <option value="WoodenWall01">WoodenWall01</option>
        {
          // eslint-disable-next-line multiline-ternary
          process.env.NODE_ENV?.includes('development') ? <>
            <option value="LMap">LMap</option>
            <option value="PocketTest">PocketTest</option>
            <option value="TunnelingTest">TunnelingTest</option>
            <option value="UndergroundTest">UndergroundTest</option>
            <option value="TunnelingTriangleTest">TunnelingTriangleTest</option>
          </> : <></>
        }
      </select>
    </div>
    <div className="lobbyMenuNext">
      <select defaultValue="0" onChange={(e) => setMaxPlayers(e.target.value)} className="lobbyMenuButtonNext">
        <option value="0" disabled>Player</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
      </select>
      <select defaultValue="0" onChange={(e) => setTime(e.target.value)} className="lobbyMenuButtonNext">
        <option value="0" disabled>Time</option>
        <option value="15">15&thinsp;s</option>
        <option value="30">30&thinsp;s</option>
        <option value="45">45&thinsp;s</option>
        <option value="60">60&thinsp;s</option>
      </select>
    </div>
    <div className='backButtonFlex'>
      <button onClick={() => lastStage()} className='backButton'>Back</button>
    </div>
    <LobbyCode lobbyId={ctx.socketId}></LobbyCode>
  </>
}

import { createHole } from '../utils/mapUtils.js'
import { GameMap } from './Map.js'

export class Moon extends GameMap {
  constructor (isClientMap) {
    super(isClientMap, 'Moon', 5)
    this.assemble()
  }

  assemble () {
    this._addCircularWater(220, 570, 40)
    this._addCircularWater(910, 321, 130)
    this._addCircularWater(420, 420, 70)

    this._addCircularWall(420, 750, 150, true, true, undefined, true)
    this._addCircularWall(140, 415, 65, true, true, undefined, true)
    this._addCircularWall(810, 75, 40, true, true, undefined, true)
    this._addCircularWall(835, 790, 55, true, true, undefined, true)
    this._addCircularWall(800, 470, 85)

    this._addCircularSand(920, 720, 90)
    this._addCircularSand(415, 80, 160)

    this._addCircularIce(730, 125, 400)

    // Loch
    this._hole = createHole(920, 720)

    this._ballSpawns = [{ x: 80, y: 80 }]

    // Linke Border
    this._addRectangularWater(0, 400, 50, 800)
    // Rechte Border
    this._addRectangularWater(1000, 400, 50, 800)
    // Obere Border
    this._addRectangularWater(500, 0, 1000, 50)
    // Untere Border
    this._addRectangularWater(500, 800, 1000, 50)
  }
}

import React, { useContext, useState } from 'react'
import GameContext from '../context/GameContext'
import './MainMenu.css'
import { emitJoinLobby } from '../clientCommunication.js'

export function MainMenu () {
  const ctx = useContext(GameContext)
  const [lobbyString, setlobbyString] = useState('')
  function nextStage () {
    ctx.setStage('lobbySettings')
  }
  function joinLobby () {
    if (lobbyString !== '') {
      emitJoinLobby(lobbyString)
    }
  }
  return <>
  <div className="mainMenu">
  <button onClick={() => nextStage()} className="menuButton">Create Lobby</button>
  <input className="optionsField" type="text" placeholder="Type in lobby id" onChange={(e) => setlobbyString(e.target.value)} />
  <button onClick={() => joinLobby()} className="menuButton">Join Lobby</button>
  </div>
  </>
}
